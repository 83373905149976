import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiService } from '../services';
import { useInvitation } from '../components/auth/useInvitation';

const RegisterPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const inviteCode = searchParams.get('invite');
  
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const { companyName } = useInvitation(inviteCode);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setIsError(false);
    setUser(null);
    
    try {
      const response = await apiService.auth.signUp(email, password);
      
      if (response.error) {
        throw response.error;
      }

      // Store the invite code in local storage for after email verification
      if (inviteCode) {
        localStorage.setItem('honeyrunsInviteCode', inviteCode);
        if (companyName) {
          localStorage.setItem('honeyrunsCompanyName', companyName);
        }
      }

      if (response.data.user) {
        setUser(response.data.user);
        if (inviteCode) {
          setMessage(`Account created! Please check your email (${email}) to verify your account. After verification, you'll be able to join ${companyName || 'the company'}.`);
        } else {
          setMessage(`Account created! Please check your email (${email}) for the verification link.`);
        }
      }
    } catch (error) {
      setIsError(true);
      setMessage(error.message || 'An error occurred during registration');
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    try {
      setLoading(true);
      const { error } = await apiService.auth.resendVerification(email);
      
      if (error) throw error;
      
      setIsError(false);
      setMessage('Verification email resent. Please check your inbox.');
    } catch (error) {
      setIsError(true);
      setMessage(error.message || 'Failed to resend verification email');
    } finally {
      setLoading(false);
    }
  };

  const navigateToLogin = () => {
    navigate('/login');
  };

  return (
    <div className="register-page">
      <div className="register-container">
        <div className="logo-section">
          <h1>HoneyRuns</h1>
        </div>
        
        <div className="register-card">
          <h2>Create Account</h2>
          {!inviteCode && (
            <p className="trial-notice">
              You're starting your 2-week free trial. No credit card required.
            </p>
          )}
          {inviteCode && (
            <p className="invite-notice">
              {companyName 
                ? `You've been invited to join ${companyName}` 
                : "You've been invited to join a company on HoneyRuns"}
            </p>
          )}
          
          <form onSubmit={handleSignUp}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            
            {message && (
              <div className={`message ${isError ? 'error' : 'success'}`}>
                {message}
              </div>
            )}
            
            {user && !user.email_confirmed_at && (
              <div className="verification-section">
                <p>Email verification pending</p>
                <button 
                  type="button" 
                  className="resend-button"
                  onClick={handleResendVerification}
                  disabled={loading}
                >
                  Resend Verification Email
                </button>
              </div>
            )}
            
            <button type="submit" className="auth-button" disabled={loading}>
              {loading ? 'Processing...' : (inviteCode 
                ? `Join ${companyName || 'Company'}` 
                : 'Start Free Trial')}
            </button>
            
            <div className="auth-toggle">
              Already have an account?{' '}
              <button 
                type="button"
                onClick={navigateToLogin} 
                className="toggle-button"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage; 