/**
 * CompanySettings - Form component for updating company information.
 * Allows users to modify company name and industry details with validation and status feedback.
 */
import React, { useState, useEffect } from 'react';
import { supabase } from '../../services';
import '../../styles/CompanySettings.css';

const CompanySettings = ({ companyId, initialCompanyData }) => {
  const [companyName, setCompanyName] = useState(initialCompanyData?.name || '');
  const [industry, setIndustry] = useState(initialCompanyData?.industry || '');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // Update local state if initialCompanyData changes
    if (initialCompanyData) {
      setCompanyName(initialCompanyData.name || '');
      setIndustry(initialCompanyData.industry || '');
    }
  }, [initialCompanyData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!companyName.trim()) {
      setIsError(true);
      setMessage('Company name is required');
      return;
    }
    
    setLoading(true);
    setMessage('');
    setIsError(false);
    
    try {
      // Update company data in Supabase
      const { data, error } = await supabase
        .from('companies')
        .update({ 
          name: companyName,
          industry: industry
        })
        .eq('id', companyId);
      
      if (error) throw error;
      
      // Call server endpoint to update company info
      const response = await fetch('/api/admin/company', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          companyId,
          name: companyName,
          industry
        }),
      });
      
      const result = await response.json();
      
      if (!result.success) {
        throw new Error(result.message);
      }
      
      setMessage('Company information updated successfully');
    } catch (error) {
      console.error('Error updating company:', error);
      setIsError(true);
      setMessage('Failed to update company information. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="company-settings">
      <h2>Company Settings</h2>
      <p className="settings-description">
        Update your company information here. This information will be displayed
        to your team members and used throughout the application.
      </p>
      
      <form className="company-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="companyName">Company Name</label>
          <input 
            type="text" 
            id="companyName"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Enter company name"
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="industry">Industry</label>
          <select 
            id="industry"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
          >
            <option value="">Select an industry</option>
            <option value="logistics">Logistics</option>
            <option value="transportation">Transportation</option>
            <option value="delivery">Delivery</option>
            <option value="construction">Construction</option>
            <option value="agriculture">Agriculture</option>
            <option value="manufacturing">Manufacturing</option>
            <option value="retail">Retail</option>
            <option value="other">Other</option>
          </select>
        </div>
        
        {message && (
          <div className={`message ${isError ? 'error' : 'success'}`}>
            {message}
          </div>
        )}
        
        <button 
          type="submit" 
          className="submit-button" 
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Save Changes'}
        </button>
      </form>
    </div>
  );
};

export default CompanySettings; 