/**
 * InviteMembers - Manages company user invitations and permissions.
 * Provides functionality to invite new users, manage existing members, 
 * and control admin privileges within a company.
 */
import React, { useState, useEffect } from 'react';
import { supabase } from '../../services';
import '../../styles/InviteMembers.css';

const InviteMembers = ({ companyName, companyId, userId, userProfile }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [actionLoading, setActionLoading] = useState(null); // Track which user action is loading

  // Load existing invitations and company users on component mount
  useEffect(() => {
    if (companyId) {
      loadInvitations();
      loadCompanyUsers();
    }
  }, [companyId]);

  // Fetch existing invitations
  const loadInvitations = async () => {
    try {
      const { data, error } = await supabase
        .from('invitations')
        .select('*')
        .eq('company_id', companyId)
        .order('created_at', { ascending: false });
      
      if (error) throw error;
      
      setInvitations(data || []);
    } catch (err) {
      console.error('Error loading invitations:', err);
    }
  };

  // Fetch company users
  const loadCompanyUsers = async () => {
    setLoadingUsers(true);
    try {
      // Get all users in this company
      const { data: companyUserData, error: companyUserError } = await supabase
        .from('user_companies')
        .select('*')
        .eq('company_id', companyId);
      
      if (companyUserError) throw companyUserError;
      
      // Get user details for each company user
      const userIds = companyUserData.map(cu => cu.user_id);
      
      // If no users, return early
      if (userIds.length === 0) {
        setCompanyUsers([]);
        return;
      }
      
      // Get user profiles
      const { data: profiles, error: profilesError } = await supabase
        .from('profiles')
        .select('id, email')
        .in('id', userIds);
        
      if (profilesError) throw profilesError;
      
      // Combine user company data with profile data
      const usersWithProfiles = companyUserData.map(user => {
        const profile = profiles.find(p => p.id === user.user_id);
        return {
          ...user,
          email: profile?.email || 'Unknown email',
          isCurrentUser: user.user_id === userId
        };
      });
      
      setCompanyUsers(usersWithProfiles);
    } catch (err) {
      console.error('Error loading company users:', err);
      setMessage('Failed to load company users');
      setIsError(true);
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    
    if (!email || !email.includes('@')) {
      setIsError(true);
      setMessage('Please enter a valid email address');
      return;
    }

    setLoading(true);
    setMessage('');
    setIsError(false);
    
    try {
      // Check if invitation already exists
      const { data: existingInvite, error: checkError } = await supabase
        .from('invitations')
        .select('id')
        .eq('company_id', companyId)
        .eq('email', email)
        .maybeSingle();
      
      if (checkError) throw checkError;
      
      if (existingInvite) {
        setIsError(true);
        setMessage('An invitation has already been sent to this email');
        return;
      }
      
      // Generate a unique invitation code
      const inviteCode = Math.random().toString(36).substring(2, 10) + 
                         Math.random().toString(36).substring(2, 10);
      
      // Insert the invitation
      const { error: insertError } = await supabase
        .from('invitations')
        .insert({
          company_id: companyId,
          invited_by: userId,
          email: email,
          status: 'pending',
          invite_code: inviteCode
        });
      
      if (insertError) throw insertError;
      
      // Clear the email field and show success message
      setEmail('');
      setMessage(`Invitation sent to ${email}`);
      
      // Reload the invitations list
      loadInvitations();
    } catch (error) {
      console.error('Error creating invitation:', error);
      setIsError(true);
      setMessage('Failed to send invitation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Generate invitation links for sharing
  const generateInviteLink = (inviteCode) => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/join?code=${inviteCode}`;
  };

  // Copy link to clipboard
  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link)
      .then(() => {
        setMessage('Invitation link copied to clipboard');
        setIsError(false);
        setTimeout(() => setMessage(''), 3000);
      })
      .catch(() => {
        setMessage('Failed to copy link');
        setIsError(true);
      });
  };

  // Toggle admin status
  const toggleAdminStatus = async (userCompanyId, currentAdminStatus, targetUserId) => {
    // Don't allow removing yourself as admin
    if (targetUserId === userId && currentAdminStatus === true) {
      setMessage("You cannot remove your own admin status");
      setIsError(true);
      return;
    }

    setActionLoading(userCompanyId);
    setMessage('');
    setIsError(false);
    
    try {
      const { error } = await supabase
        .from('user_companies')
        .update({ is_admin: !currentAdminStatus })
        .eq('id', userCompanyId);
        
      if (error) throw error;
      
      setMessage(`User admin status updated successfully`);
      loadCompanyUsers(); // Reload users to show updated status
    } catch (error) {
      console.error('Error updating admin status:', error);
      setIsError(true);
      setMessage('Failed to update admin status. Please try again.');
    } finally {
      setActionLoading(null);
    }
  };

  // Remove user from company
  const removeUserFromCompany = async (userCompanyId, targetUserId) => {
    // Don't allow removing yourself
    if (targetUserId === userId) {
      setMessage("You cannot remove yourself from the company");
      setIsError(true);
      return;
    }

    if (!window.confirm('Are you sure you want to remove this user from the company?')) {
      return;
    }
    
    setActionLoading(userCompanyId);
    setMessage('');
    setIsError(false);
    
    try {
      const { error } = await supabase
        .from('user_companies')
        .delete()
        .eq('id', userCompanyId);
        
      if (error) throw error;
      
      setMessage(`User removed from company successfully`);
      loadCompanyUsers(); // Reload users to show updated list
    } catch (error) {
      console.error('Error removing user:', error);
      setIsError(true);
      setMessage('Failed to remove user. Please try again.');
    } finally {
      setActionLoading(null);
    }
  };

  return (
    <div className="invite-members-container">
      <h2>Invite Team Members</h2>
      <p className="invite-description">
        Invite team members to join {companyName || 'your company'} on HoneyRuns.
      </p>

      <form onSubmit={handleInvite} className="invite-form">
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <div className="input-with-button">
            <input
              type="email"
              id="email"
              placeholder="colleague@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button 
              type="submit" 
              className="invite-button" 
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Send Invite'}
            </button>
          </div>
        </div>
      </form>

      {message && (
        <div className={`message ${isError ? 'error' : 'success'}`}>
          {message}
        </div>
      )}
      
      {/* Display company users */}
      <div className="company-users">
        <h3>Company Members</h3>
        {loadingUsers ? (
          <p>Loading users...</p>
        ) : companyUsers.length === 0 ? (
          <p className="no-users">No users found in your company</p>
        ) : (
          <ul className="users-list">
            {companyUsers.map(user => (
              <li key={user.id} className="user-item">
                <div className="user-info">
                  <span className="user-email">{user.email}</span>
                  {user.isCurrentUser && <span className="current-user-badge">(You)</span>}
                  <span className={`admin-badge ${user.is_admin ? 'is-admin' : ''}`}>
                    {user.is_admin ? 'Admin' : 'Member'}
                  </span>
                </div>
                
                <div className="user-actions">
                  <button
                    className={`admin-toggle-button ${user.is_admin ? 'remove-admin' : 'make-admin'}`}
                    onClick={() => toggleAdminStatus(user.id, user.is_admin, user.user_id)}
                    disabled={actionLoading === user.id || user.isCurrentUser}
                  >
                    {actionLoading === user.id ? 'Updating...' : user.is_admin ? 'Remove Admin' : 'Make Admin'}
                  </button>
                  
                  <button
                    className="remove-user-button"
                    onClick={() => removeUserFromCompany(user.id, user.user_id)}
                    disabled={actionLoading === user.id || user.isCurrentUser}
                  >
                    {actionLoading === user.id ? 'Removing...' : 'Remove'}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      
      {/* Display invitations */}
      {invitations.length > 0 && (
        <div className="invitations-list">
          <h3>Pending Invitations</h3>
          <ul>
            {invitations.map(invite => (
              <li key={invite.id} className="invite-item">
                <div className="invite-email">{invite.email}</div>
                <div className="invite-status">{invite.status || 'pending'}</div>
                <div className="invite-link">
                  <input 
                    type="text" 
                    value={generateInviteLink(invite.invite_code)}
                    readOnly
                  />
                  <button 
                    onClick={() => copyToClipboard(generateInviteLink(invite.invite_code))}
                    className="copy-button"
                  >
                    Copy Link
                  </button>
                </div>
                <div className="invite-date">
                  Sent on {new Date(invite.created_at).toLocaleDateString()}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InviteMembers; 