import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { dimoService } from '../services';
import '../styles/Telematics.css';

const TelematicsPage = ({ userProfile }) => {
  const [companyId, setCompanyId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const navigate = useNavigate();

  // Set company ID from user profile
  useEffect(() => {
    if (userProfile?.companyId) {
      setCompanyId(userProfile.companyId);
      setIsAdmin(userProfile.isAdmin);
    }
  }, [userProfile]);

  const handleRefresh = () => setRefreshCount(prev => prev + 1);
  
  const reconnectDimo = () => {
    navigate(`/telematics/dimo/authorize?companyId=${companyId}${userProfile?.userId ? `&userId=${userProfile.userId}` : ''}`);
  };

  const handleGoToConnect = () => navigate('/admin');

  // Render content based on state
  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="loading-container">
          <p>Loading telematics data...</p>
        </div>
      );
    }
    
    if (error) {
      return (
        <div className="error-container">
          <div className="error-message">
            <p>{error}</p>
            <div className="button-container">
              <button className="connect-button" onClick={() => setError(null)}>
                Dismiss
              </button>
            </div>
          </div>
        </div>
      );
    }
    
    // Temporarily showing placeholder until DIMO integration is fixed
    return (
      <div className="telematics-content">
        <div className="no-connection-container">
          <div className="no-connection-message">
            <h3>DIMO Integration Update in Progress</h3>
            <p>Our DIMO integration is currently being updated to improve performance and functionality.</p>
            <p>Vehicle data is still accessible through the API endpoints.</p>
            {isAdmin && (
              <div>
                <button className="refresh-button" onClick={handleRefresh} style={{ marginTop: '10px' }}>
                  Refresh Status
                </button>
              </div>
            )}
          </div>
        </div>
        
        <div className="vehicle-list">
          <h3>API Data Access</h3>
          <p>Vehicle data is available via the <code>/api/check-vehicles</code> endpoint.</p>
        </div>
      </div>
    );
    
    /* Original code commented out to fix React Error #31
    if (!companyDimoConnected) {
      return (
        <div className="no-connection-container">
          <div className="no-connection-message">
            <h3>No Telematics Provider Connected</h3>
            <p>To view vehicle locations and data, you need to connect a telematics provider.</p>
            {isAdmin ? (
              <div>
                <button className="connect-button" onClick={handleGoToConnect}>
                  Go to Connect Settings
                </button>
                <button className="refresh-button" onClick={handleRefresh} style={{ marginLeft: '10px' }}>
                  Refresh Status
                </button>
                <p className="connect-hint">
                  In the Admin Dashboard, go to the "Connect" tab to set up your telematics integration.
                  If you've just connected, click Refresh Status to update the page.
                </p>
              </div>
            ) : (
              <p>Please contact your administrator to connect a telematics provider.</p>
            )}
          </div>
        </div>
      );
    }
    
    return (
      <div className="telematics-content">
        <div className="connection-status">
          <p className="connected-status">✅ Connected to DIMO telematics</p>
          <div className="action-buttons">
            <button 
              className="refresh-button" 
              onClick={handleRefresh}
            >
              Refresh
            </button>
          </div>
        </div>
        
        <div className="auth-data-container">
          <h3>DIMO Connection Information</h3>
          {authData && (
            <div className="auth-data-details">
              {authData.sharedVehicles && authData.sharedVehicles.length > 0 ? (
                <>
                  <p><strong>Shared Vehicles:</strong></p>
                  <ul>
                    {authData.sharedVehicles.map((tokenId, index) => (
                      <li key={index}>
                        <div className="vehicle-info">
                          <div><strong>Vehicle Token ID:</strong> {tokenId}</div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <p><strong>Vehicle Token ID:</strong> No vehicles found</p>
              )}
            </div>
          )}
        </div>
      </div>
    );
    */
  };

  return (
    <div className="telematics-page">
      <h1>Telematics</h1>
      {renderContent()}
    </div>
  );
};

export default TelematicsPage; 