import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../services';

const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setIsError(false);
    
    try {
      const response = await apiService.auth.signIn(email, password);
      
      if (response.error) {
        throw response.error;
      }

      if (response.data.user) {
        const user = response.data.user;
        
        if (!user.email_confirmed_at && user.confirmation_sent_at) {
          setMessage(`Please confirm your email (${email}) to fully activate your account.`);
        } else {
          // Check for stored invite code
          const storedInviteCode = localStorage.getItem('honeyrunsInviteCode');
          if (storedInviteCode) {
            setMessage('Login successful! Redirecting to join page...');
            setTimeout(() => {
              navigate(`/join?code=${storedInviteCode}`, { replace: true });
            }, 1500);
          } else {
            setMessage('Login successful! Redirecting...');
            setTimeout(() => {
              navigate('/dashboard', { replace: true });
            }, 1500);
          }
        }
      }
    } catch (error) {
      setIsError(true);
      setMessage(error.message || 'An error occurred during login');
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    try {
      setLoading(true);
      const { error } = await apiService.auth.resendVerification(email);
      
      if (error) throw error;
      
      setIsError(false);
      setMessage('Verification email resent. Please check your inbox.');
    } catch (error) {
      setIsError(true);
      setMessage(error.message || 'Failed to resend verification email');
    } finally {
      setLoading(false);
    }
  };

  const navigateToRegister = () => {
    navigate('/register');
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="logo-section">
          <h1>HoneyRuns</h1>
        </div>
        
        <div className="login-card">
          <h2>Login</h2>
          
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            
            {message && (
              <div className={`message ${isError ? 'error' : 'success'}`}>
                {message}
              </div>
            )}
            
            {isError && message.includes('confirm your email') && (
              <div className="verification-section">
                <button 
                  type="button" 
                  className="resend-button"
                  onClick={handleResendVerification}
                  disabled={loading}
                >
                  Resend Verification Email
                </button>
              </div>
            )}
            
            <button type="submit" className="auth-button" disabled={loading}>
              {loading ? 'Processing...' : 'Login'}
            </button>
            
            <div className="auth-toggle">
              Don't have an account?{' '}
              <button 
                type="button"
                onClick={navigateToRegister} 
                className="toggle-button"
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage; 