/**
 * TelematicsConnect - Manages vehicle telematics connections through the DIMO service.
 * Handles authentication, connection states, and provides UI for connecting/disconnecting vehicles.
 */
import React, { useState } from 'react';
import { dimoService } from '../../services';
import '../../styles/Telematics.css';

const TelematicsConnect = ({ userProfile }) => {
  const companyId = userProfile?.companyId;
  const [connected, setConnected] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [companyDimoConnected, setCompanyDimoConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Changed to false to avoid loading indicator
  const [error, setError] = useState(null);
  
  /* 
  // Commenting out DIMO integration code to fix React Error #31
  const [debugInfo, setDebugInfo] = useState({});
  const lastRequestTime = useRef(0);
  const initialCheckDone = useRef(false);

  // Check if company already has DIMO connected
  useEffect(() => {
    // implementation removed
  }, [companyId, userProfile?.userId]);

  // Function to disconnect DIMO integration
  const disconnectDimo = async () => {
    // implementation removed
  };

  // DIMO Login Component
  const DimoLoginButton = () => {
    // implementation removed
  };

  // Function to manually refresh connection status
  const refreshConnectionStatus = async () => {
    // implementation removed
  };
  */

  // Simplified DIMO placeholder component that doesn't use the problematic SDK
  const DimoLoginButton = () => {
    return (
      <div className="dimo-login-container">
        <h3>Connect with DIMO</h3>
        <p>DIMO integration is currently being updated.</p>
        <p>Please use the DIMO API endpoints directly through the backend for now.</p>
      </div>
    );
  };

  return (
    <div className="telematics-connect">
      <h2>Connect Telematics Providers</h2>
      
      {isLoading ? (
        <div className="loading-container">
          <p>Loading telematics connection status...</p>
        </div>
      ) : (
        <div className="providers-container">
          {/* Display error as a notification instead of replacing content */}
          {error && (
            <div className="error-notification" style={{ 
              gridColumn: "1 / -1", 
              background: "#ffebee", 
              color: "#d32f2f", 
              padding: "10px 15px", 
              borderRadius: "4px", 
              marginBottom: "20px" 
            }}>
              <p>{error}</p>
              <button 
                onClick={() => setError(null)}
                style={{ 
                  padding: "5px 10px", 
                  marginTop: "5px", 
                  background: "#f5f5f5", 
                  border: "1px solid #ddd",
                  borderRadius: "4px" 
                }}
              >
                Dismiss
              </button>
            </div>
          )}
          
          {/* DIMO Integration */}
          <div className="provider-card">
            <DimoLoginButton />
          </div>
          
          {/* 
          // Debug info section - commented out
          {Object.keys(debugInfo).length > 0 && (
            <div className="debug-info">
              // implementation removed
            </div>
          )}
          */}
          
          {/* Other providers */}
          <div className="provider-card">
            <h3>Other Telematics Providers</h3>
            {!connected ? (
              <div className="provider-connection">
                <p>Connect to another telematics provider to view vehicle data.</p>
                <select 
                  className="provider-select" 
                  value={selectedProvider} 
                  onChange={(e) => setSelectedProvider(e.target.value)}
                >
                  <option value="">Select Provider</option>
                  <option value="samsara">Samsara</option>
                  <option value="geotab">Geotab</option>
                  <option value="fleetio">Fleetio</option>
                </select>
                <button 
                  className="connect-button" 
                  onClick={() => setConnected(true)}
                  disabled={!selectedProvider}
                >
                  Connect
                </button>
              </div>
            ) : (
              <div className="provider-connected">
                <p>Connected to {selectedProvider}</p>
                <button onClick={() => setConnected(false)}>Disconnect</button>
              </div>
            )}
          </div>
          
          <div className="telematics-note">
            <p><strong>Note:</strong> Connecting a telematics provider will allow you to view your vehicles 
            and their status on the Telematics page.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TelematicsConnect; 