import { supabase } from './SupabaseService';

// Standard API service for consistent data access
const apiService = {
  // Authentication methods
  auth: {
    signUp: async (email, password) => {
      return await supabase.auth.signUp({
        email,
        password,
        options: { data: { email } }
      });
    },
    
    signIn: async (email, password) => {
      return await supabase.auth.signInWithPassword({
        email,
        password
      });
    },
    
    signOut: async () => {
      return await supabase.auth.signOut();
    },
    
    getSession: async () => {
      return await supabase.auth.getSession();
    },
    
    resendVerification: async (email) => {
      return await supabase.auth.resend({
        type: 'signup',
        email
      });
    }
  },
  
  // Company methods
  company: {
    getCompany: async (companyId) => {
      return await supabase
        .from('companies')
        .select('*')
        .eq('id', companyId)
        .single();
    },
    
    updateCompany: async (companyId, data) => {
      return await supabase
        .from('companies')
        .update(data)
        .eq('id', companyId);
    },
    
    createCompany: async (data) => {
      return await supabase
        .from('companies')
        .insert(data)
        .select();
    }
  },
  
  // User methods
  user: {
    getUserCompany: async (userId) => {
      return await supabase
        .from('user_companies')
        .select('*')
        .eq('user_id', userId)
        .maybeSingle();
    },
    
    linkUserToCompany: async (data) => {
      return await supabase
        .from('user_companies')
        .insert(data)
        .select();
    },
    
    updateUserCompany: async (id, data) => {
      return await supabase
        .from('user_companies')
        .update(data)
        .eq('id', id);
    }
  },
  
  // Invitation methods
  invitations: {
    getInvitation: async (inviteCode) => {
      return await supabase
        .from('invitations')
        .select('*')
        .eq('invite_code', inviteCode)
        .maybeSingle();
    },
    
    createInvitation: async (data) => {
      return await supabase
        .from('invitations')
        .insert(data)
        .select();
    },
    
    updateInvitation: async (id, data) => {
      return await supabase
        .from('invitations')
        .update(data)
        .eq('id', id);
    },
    
    listCompanyInvitations: async (companyId) => {
      return await supabase
        .from('invitations')
        .select('*')
        .eq('company_id', companyId)
        .order('created_at', { ascending: false });
    }
  },
  
  // Personnel methods
  personnel: {
    getCompanyPersonnel: async (companyId) => {
      return await supabase
        .from('operations_personnel')
        .select('*')
        .eq('company_id', companyId);
    },
    
    addPersonnel: async (data) => {
      return await supabase
        .from('operations_personnel')
        .insert(data)
        .select();
    },
    
    updatePersonnel: async (id, data) => {
      return await supabase
        .from('operations_personnel')
        .update(data)
        .eq('id', id);
    },
    
    deletePersonnel: async (id) => {
      return await supabase
        .from('operations_personnel')
        .delete()
        .eq('id', id);
    }
  }
};

export { supabase, apiService };
export default apiService; 