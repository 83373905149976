import React, { useState, useEffect, createContext, useContext } from 'react';
import './App.css';
import './styles/auth.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import AuthCallback from './components/auth/AuthCallback';
import CompanyRegistration from './components/onboarding/CompanyRegistration';
import InviteMembers from './components/company/InviteMembers';
import JoinCompany from './components/onboarding/JoinCompany';
import MainLayout from './components/layout/MainLayout';
import Dashboard from './pages/Dashboard';
import TelematicsPage from './pages/TelematicsPage';
import CommunicationsPage from './pages/CommunicationsPage';
import AdminDashboard from './pages/AdminDashboard';
import VehicleDetailPage from './pages/VehicleDetailPage';
import { supabase, dimoService, apiService } from './services';

// Context for auth and user data
const AuthContext = createContext(null);

// Custom hook to get URL parameters
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Custom hook for auth and user state
function useAuth() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Define public paths
  const publicPaths = ['/login', '/register', '/', '/auth/callback'];
  const isPublicPath = publicPaths.includes(location.pathname);

  // Initialize auth state
  useEffect(() => {
    // Set up Supabase auth state listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
        
        if (session) {
          // Check for stored invite code
          const storedInviteCode = localStorage.getItem('honeyrunsInviteCode');
          
          if (storedInviteCode && !location.pathname.includes('/join')) {
            navigate(`/join?code=${storedInviteCode}`, { replace: true });
          } else {
            fetchUserProfile(session.user.id);
          }
        } else {
          setUserProfile(null);
          setLoading(false);
        }
      }
    );

    // Initial session check
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      if (session) {
        const storedInviteCode = localStorage.getItem('honeyrunsInviteCode');
        
        if (storedInviteCode && !location.pathname.includes('/join')) {
          navigate(`/join?code=${storedInviteCode}`, { replace: true });
        } else {
          fetchUserProfile(session.user.id);
        }
      } else {
        setLoading(false);
      }
    });

    return () => subscription?.unsubscribe();
  }, [navigate, location.pathname]);

  // Fetch user profile 
  const fetchUserProfile = async (userId) => {
    try {
      // Get user company association
      const { data: userCompany, error: companyError } = await apiService.user.getUserCompany(userId);

      if (companyError) throw companyError;

      if (userCompany) {
        // User has a company - fetch company details
        const { data: company, error: companyDetailsError } = 
          await apiService.company.getCompany(userCompany.company_id);
          
        if (companyDetailsError) throw companyDetailsError;
        
        // Set user profile with company details
        setUserProfile({
          userId,
          companyId: userCompany.company_id,
          companyName: company?.name || 'Your Company',
          isAdmin: userCompany.is_admin === true
        });
        
        // Redirect to dashboard only if on login page
        if (location.pathname === '/login') {
          navigate('/dashboard', { replace: true });
        }
      } else {
        // No company, set minimal profile
        setUserProfile({ userId, needsCompany: true });
        
        // Only redirect to registration from login or dashboard
        if (location.pathname === '/login' || location.pathname === '/dashboard') {
          navigate('/register-company', { replace: true });
        }
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Handle company registration
  const handleCompanyRegistered = (companyId) => {
    if (session) {
      fetchUserProfile(session.user.id);
      navigate('/dashboard', { replace: true });
    }
  };
  
  return {
    session,
    loading,
    userProfile,
    error,
    handleCompanyRegistered,
    refreshProfile: () => session && fetchUserProfile(session.user.id),
    isPublicPath
  };
}

// Component to handle joining a company via invite
function JoinCompanyHandler() {
  const query = useQuery();
  const navigate = useNavigate();
  const inviteCode = query.get('code');
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });
  }, []);

  if (!inviteCode) return <Navigate to="/" />;
  if (loading) return <div className="loading">Checking your invitation...</div>;

  if (!session) {
    localStorage.setItem('honeyrunsInviteCode', inviteCode);
    return <Navigate to={`/register?invite=${inviteCode}`} replace />;
  }

  return (
    <JoinCompany 
      userId={session.user.id}
      inviteCode={inviteCode}
      onComplete={() => navigate('/')} 
    />
  );
}

// Protected route component
function ProtectedRoute({ children, requireCompany = true }) {
  const { session, userProfile, loading, isPublicPath } = useContext(AuthContext);
  const location = useLocation();
  
  // Skip protection for public paths
  if (isPublicPath) return children;
  
  // Handle loading, authentication, and company requirements
  if (loading) return <div className="loading-screen">Loading...</div>;
  if (!session) return <Navigate to="/login" />;
  if (requireCompany && userProfile?.needsCompany && 
     !location.pathname.includes('/register-company')) {
    return <Navigate to="/register-company" />;
  }
  
  return children;
}

// Main content component
function AppContent() {
  const auth = useAuth();
  const { session, userProfile, loading, error, handleCompanyRegistered, isPublicPath } = auth;
  
  if (loading && !isPublicPath) {
    return <div className="loading-screen">Loading app...</div>;
  }
  
  if (error) {
    return (
      <div className="error-screen">
        <h1>Something went wrong</h1>
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Refresh</button>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={auth}>
      <div className="App">
        <dimoService.DimoAuthProvider>
          <Routes>
            {/* Public routes */}
            <Route path="/register" element={
              session ? <Navigate to="/dashboard" /> : <RegisterPage />
            } />
            <Route path="/login" element={
              session ? <Navigate to="/dashboard" /> : <LoginPage />
            } />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route path="/join" element={<JoinCompanyHandler />} />
            
            {/* Home route */}
            <Route path="/" element={
              !session ? <Navigate to="/login" /> : 
              (userProfile?.needsCompany ? <Navigate to="/register-company" /> : 
               <Navigate to="/dashboard" />)
            } />
            
            {/* Company registration */}
            <Route path="/register-company" element={
              <ProtectedRoute requireCompany={false}>
                <CompanyRegistration 
                  userId={session?.user.id} 
                  onComplete={handleCompanyRegistered}
                />
              </ProtectedRoute>
            } />
            
            {/* Main app routes */}
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <MainLayout session={session} userProfile={userProfile}>
                  <Dashboard />
                </MainLayout>
              </ProtectedRoute>
            } />
            
            <Route path="/telematics" element={
              <ProtectedRoute>
                <MainLayout session={session} userProfile={userProfile}>
                  <TelematicsPage />
                </MainLayout>
              </ProtectedRoute>
            } />
            
            <Route path="/vehicle/:vehicleId" element={
              <ProtectedRoute>
                <MainLayout session={session} userProfile={userProfile}>
                  <VehicleDetailPage />
                </MainLayout>
              </ProtectedRoute>
            } />
            
            <Route path="/communications" element={
              <ProtectedRoute>
                <MainLayout session={session} userProfile={userProfile}>
                  <CommunicationsPage />
                </MainLayout>
              </ProtectedRoute>
            } />
            
            {/* Admin routes */}
            <Route path="/admin" element={
              <ProtectedRoute>
                {userProfile?.isAdmin ? (
                  <MainLayout session={session} userProfile={userProfile}>
                    <AdminDashboard />
                  </MainLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )}
              </ProtectedRoute>
            } />
            
            <Route path="/admin/invite" element={
              <ProtectedRoute>
                {userProfile?.isAdmin ? (
                  <MainLayout session={session} userProfile={userProfile}>
                    <InviteMembers companyId={userProfile.companyId} />
                  </MainLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )}
              </ProtectedRoute>
            } />
            
            {/* Fallback route */}
            <Route path="*" element={
              <Navigate to={session ? "/dashboard" : "/login"} />
            } />
          </Routes>
        </dimoService.DimoAuthProvider>
      </div>
    </AuthContext.Provider>
  );
}

// App wrapper for Router
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
