import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/VehicleDetailPage.css';

const VehicleDetailPage = () => {
  const { vehicleId } = useParams();
  const navigate = useNavigate();

  return (
    <div className="vehicle-detail-page">
      <div className="page-header">
        <button className="back-button" onClick={() => navigate('/telematics')}>
          ← Back to Telematics
        </button>
        <h1>Vehicle Details</h1>
      </div>
      
      <div className="vehicle-info-container">
        <h2>Vehicle Information</h2>
        <p>This page would display detailed information for vehicle ID: {vehicleId}</p>
        <p>Connect a telematics provider to see actual vehicle details.</p>
        
        <div className="info-action">
          <button onClick={() => navigate('/telematics')}>
            Configure Telematics
          </button>
        </div>
      </div>
    </div>
  );
};

export default VehicleDetailPage; 