/**
 * JoinCompany - Processes company invitations during user onboarding.
 * Verifies invitation codes, adds users to companies, and updates invitation status.
 * Provides feedback on success or error conditions.
 */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services';
import '../../styles/JoinCompany.css';

const JoinCompany = ({ userId, inviteCode, onComplete }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [invitation, setInvitation] = useState(null);
  const [company, setCompany] = useState(null);
  const [success, setSuccess] = useState(false);

  // Verify the invitation code on component mount
  useEffect(() => {
    if (inviteCode && userId) {
      verifyInvitation();
    }
  }, [inviteCode, userId]);

  // Verify the invitation and fetch company details
  const verifyInvitation = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!inviteCode) {
        throw new Error('No invitation code provided');
      }

      // Look up the invitation using the invite code
      const { data: invite, error: inviteError } = await supabase
        .from('invitations')
        .select('*')
        .eq('invite_code', inviteCode)
        .maybeSingle();

      if (inviteError || !invite) {
        throw new Error('Invalid or expired invitation');
      }
      
      setInvitation(invite);

      // Fetch company details
      const { data: companyData, error: companyError } = await supabase
        .from('companies')
        .select('*')
        .eq('id', invite.company_id)
        .single();

      if (companyError || !companyData) {
        throw new Error('Could not retrieve company information');
      }
      
      setCompany(companyData);

      // Check if user is already a member of this company
      const { data: existingMembership, error: membershipError } = await supabase
        .from('user_companies')
        .select('*')
        .eq('user_id', userId)
        .eq('company_id', invite.company_id)
        .maybeSingle();
      
      if (membershipError) {
        console.error('Error checking membership:', membershipError);
      }

      // If user is already a member, show a different message
      if (existingMembership) {
        setSuccess(true);
        return;
      }

      // If not already a member, join the company automatically
      await joinCompany();

    } catch (error) {
      console.error('Error verifying invitation:', error);
      setError(error.message || 'Invalid invitation');
    } finally {
      setLoading(false);
    }
  };

  // Join the company automatically
  const joinCompany = async () => {
    try {
      if (!invitation || !userId) return;

      // Create user_companies record
      const { error: joinError } = await supabase
        .from('user_companies')
        .insert({
          user_id: userId,
          company_id: invitation.company_id,
          is_admin: false
        });

      if (joinError) throw joinError;

      // Update invitation status
      const { error: updateError } = await supabase
        .from('invitations')
        .update({ status: 'accepted' })
        .eq('id', invitation.id);

      if (updateError) throw updateError;

      // Set success state
      setSuccess(true);
      
      // Clean up any stored invitation codes
      localStorage.removeItem('honeyrunsInviteCode');
      localStorage.removeItem('honeyrunsCompanyName');
      
    } catch (error) {
      console.error('Error joining company:', error);
      setError('Failed to join company. Please try again.');
    }
  };

  if (loading) {
    return <div className="loading-container">Processing your invitation...</div>;
  }

  // Show success state
  if (success) {
    return (
      <div className="join-company-container">
        <div className="join-company-card success-card">
          <h2>Welcome to {company?.name}!</h2>
          <p className="success-message">
            You have successfully joined the company.
          </p>
          <button 
            className="join-button"
            onClick={() => navigate('/dashboard', { replace: true })}
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="join-company-container">
        <div className="join-company-card">
          <h2>Invalid Invitation</h2>
          <p className="error-message">{error}</p>
          <button 
            className="join-button"
            onClick={() => navigate('/', { replace: true })}
          >
            Return to Home
          </button>
        </div>
      </div>
    );
  }

  // This should rarely be shown since we join automatically now
  return (
    <div className="join-company-container">
      <div className="join-company-card">
        <h2>Join Company</h2>
        <p className="info-text">
          You have been invited to join <strong>{company?.name}</strong> on HoneyRuns.
        </p>

        <button 
          className="join-button"
          onClick={joinCompany}
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Accept Invitation & Join'}
        </button>
      </div>
    </div>
  );
};

export default JoinCompany; 