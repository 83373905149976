/**
 * DIMO Service - Consolidated DIMO client functionality
 * Provides API client, React components, and utility functions
 */

import axios from 'axios';

// Use environment variable with REACT_APP_ prefix for the API URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api/telematics/dimo';

// ------------------------------
// API Client Functions
// ------------------------------

/**
 * Get authentication data for a company
 * @param {string} companyId - The company ID
 * @param {string} userId - Optional user ID
 * @returns {Promise<Object>} - Authentication data
 */
export const getAuthData = async (companyId, userId = null) => {
  try {
    /* Commenting out actual implementation to prevent React Error #31
    const params = { companyId };
    if (userId) params.userId = userId;
    
    const response = await axios.get(`${API_BASE_URL}/auth-data`, { params });
    return response.data;
    */
    console.log('DIMO getAuthData called - integration disabled');
    return { success: false, message: 'DIMO integration temporarily disabled for maintenance' };
  } catch (error) {
    console.error('Error getting DIMO auth data:', error);
    throw error;
  }
};

/**
 * Store authentication data for a company
 * @param {Object} authData - The auth data from DIMO
 * @param {string} companyId - The company ID
 * @param {string} userId - Optional user ID
 * @returns {Promise<Object>} - Response from the server
 */
export const storeAuthData = async (authData, companyId, userId = null) => {
  try {
    /* Commenting out actual implementation to prevent React Error #31
    const response = await axios.post(`${API_BASE_URL}/store-auth`, {
      companyId,
      authData,
      userId
    });
    return response.data;
    */
    console.log('DIMO storeAuthData called - integration disabled');
    return { success: false, message: 'DIMO integration temporarily disabled for maintenance' };
  } catch (error) {
    console.error('Error storing DIMO auth data:', error);
    throw error;
  }
};

/**
 * Remove authentication data for a company
 * @param {string} companyId - The company ID
 * @param {string} userId - Optional user ID
 * @returns {Promise<Object>} - Response from the server
 */
export const removeAuthData = async (companyId, userId = null) => {
  try {
    /* Commenting out actual implementation to prevent React Error #31
    const response = await axios.post(`${API_BASE_URL}/remove-auth`, {
      companyId,
      userId
    });
    return response.data;
    */
    console.log('DIMO removeAuthData called - integration disabled');
    return { success: false, message: 'DIMO integration temporarily disabled for maintenance' };
  } catch (error) {
    console.error('Error removing DIMO auth data:', error);
    throw error;
  }
};

/**
 * Fetch vehicles from DIMO
 * @param {string} authToken - Authentication token
 * @returns {Promise<Object>} - Vehicle data
 */
export const getVehicles = async (authToken) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/vehicles`, {
      params: { token: authToken }
    });
    return response.data;
  } catch (error) {
    console.error('Error getting DIMO vehicles:', error);
    throw error;
  }
};

/**
 * Count DIMO vehicles (public API)
 * @returns {Promise<Object>} - Count data
 */
export const countDimoVehicles = async () => {
  try {
    console.log('Making request to count DIMO vehicles (public)');
    const response = await axios.get(`${API_BASE_URL}/count-vehicles`);
    console.log('DIMO public vehicle count response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error counting DIMO vehicles:', error);
    throw error;
  }
};

/**
 * Get DIMO service health information
 * @returns {Promise<Object>} - Health data
 */
export const getDimoHealth = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/health`);
    return response.data;
  } catch (error) {
    console.error('Error getting DIMO health:', error);
    throw error;
  }
};

/**
 * Count DIMO vehicles with identity API (previously authenticated)
 * @returns {Promise<Object>} - Count data
 */
export const countDimoVehiclesWithIdentityApi = async () => {
  try {
    console.log('Making request to count DIMO vehicles (identity API)');
    const response = await axios.get(`${API_BASE_URL}/count-vehicles-auth`);
    console.log('DIMO identity API vehicle count response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error counting DIMO vehicles with identity API:', error);
    throw error;
  }
};

/**
 * Get Vehicle JWT directly using the server's developer JWT
 * @param {string} tokenId - DIMO token ID
 * @returns {Promise<Object>} - Vehicle JWT data
 */
export const getVehicleJwtDirect = async (tokenId) => {
  try {
    console.log(`Requesting Vehicle JWT directly for token ID: ${tokenId}`);
    
    const response = await axios.get(`${API_BASE_URL}/vehicle-jwt/${tokenId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting Vehicle JWT directly:', error);
    throw error;
  }
};

/**
 * Test telemetry API with a token ID
 * @param {string} tokenId - DIMO token ID
 * @returns {Promise<Object>} - Telemetry data
 */
export const testTelemetryApi = async (tokenId) => {
  try {
    console.log(`Testing telemetry API for token ID: ${tokenId}`);
    const response = await axios.get(`${API_BASE_URL}/test-telemetry/${tokenId}`);
    console.log('Telemetry API test response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error testing telemetry API:', error);
    throw error;
  }
};

/**
 * Refresh vehicle JWT and save to database
 * @param {string} tokenId - DIMO token ID
 * @param {string} vehicleId - Database vehicle ID
 * @returns {Promise<Object>} - Refresh result
 */
export const refreshVehicleJwt = async (tokenId, vehicleId) => {
  try {
    console.log(`Refreshing vehicle JWT for token ID: ${tokenId}, vehicle ID: ${vehicleId}`);
    const response = await axios.post(`${API_BASE_URL}/refresh-vehicle-jwt`, {
      tokenId,
      vehicleId
    });
    console.log('Vehicle JWT refresh response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error refreshing vehicle JWT:', error);
    throw error;
  }
};

/**
 * Refresh vehicle JWT and query telemetry in one call
 * @param {string} tokenId - DIMO token ID
 * @param {string} vehicleId - Database vehicle ID
 * @returns {Promise<Object>} - Refresh and query result
 */
export const refreshAndQueryTelemetry = async (tokenId, vehicleId) => {
  try {
    console.log(`Refreshing JWT and querying telemetry for token ID: ${tokenId}, vehicle ID: ${vehicleId}`);
    const response = await axios.post(`${API_BASE_URL}/refresh-and-query`, {
      tokenId,
      vehicleId
    });
    console.log('Refresh and query response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error refreshing JWT and querying telemetry:', error);
    throw error;
  }
};

/**
 * Get mock vehicle data for testing
 * @returns {Object} - Mock vehicle data
 */
export const getMockVehicleData = () => {
  return {
    vehicles: [
      {
        id: '1',
        name: 'Test Vehicle 1',
        make: 'Toyota',
        model: 'Camry',
        year: 2022
      },
      {
        id: '2',
        name: 'Test Vehicle 2',
        make: 'Honda',
        model: 'Accord',
        year: 2021
      }
    ]
  };
};

// ------------------------------
// React Components and UI Functions - Commented out to fix Error #31
// ------------------------------

/**
 * React component to share vehicles (placeholder)
 */
export const ShareVehiclesWithDimo = () => {
  // Return null instead of an object to prevent React Error #31
  return null;
};

/**
 * Auth provider component (placeholder)
 */
export const DimoAuthProvider = ({ children }) => {
  // Just render children without any provider wrapper
  return children;
};

/**
 * Auth state hook (placeholder)
 */
export const useDimoAuthState = () => {
  return { 
    isAuthenticated: false, 
    authData: null, 
    error: null, 
    login: () => console.log('DIMO login disabled'), 
    logout: () => console.log('DIMO logout disabled') 
  };
};

/**
 * Initialize SDK function (simplified)
 */
export const initializeDimo = (config) => {
  console.log('DIMO initialization disabled - config:', config);
}; 