/**
 * CompanyRegistration - Handles new company creation during user onboarding.
 * Creates a company record, associates it with the current user as admin,
 * and provides error handling with debug information.
 */
import React, { useState } from 'react';
import { supabase } from '../../services';
import '../../styles/CompanyRegistration.css';

const CompanyRegistration = ({ userId, onComplete }) => {
  const [companyName, setCompanyName] = useState('');
  const [industry, setIndustry] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setDebugInfo(null);

    try {
      console.log('Creating company with name:', companyName, 'and user ID:', userId);
      
      // Simple company data with just the required fields
      const companyData = {
        name: companyName,
        industry: industry
      };
      
      // Insert the company with minimal data
      console.log('Inserting company with data:', companyData);
      const { data, error: companyError } = await supabase
        .from('companies')
        .insert(companyData)
        .select();

      if (companyError) {
        console.error('Error creating company:', companyError);
        
        // Store detailed error info for debugging
        setDebugInfo({
          error: companyError,
          message: 'Error occurred while creating company',
          data: companyData
        });
        
        throw companyError;
      }

      console.log('Company created successfully:', data);
      
      if (data && data[0]) {
        const companyId = data[0].id;
        await linkUserToCompany(userId, companyId);
      } else {
        throw new Error('No company ID returned');
      }
    } catch (error) {
      console.error('Error registering company:', error);
      setError(`Unable to create company: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  // Separate function to link user to company
  const linkUserToCompany = async (userId, companyId) => {
    try {
      console.log('Linking user to company as admin');
      
      const userData = {
        user_id: userId,
        company_id: companyId,
        is_admin: true
      };
      
      console.log('Inserting into user_companies table:', userData);
      const { data, error: linkError } = await supabase
        .from('user_companies')
        .insert(userData)
        .select();
        
      if (linkError) {
        console.error('Error linking user to company:', linkError);
        setDebugInfo({
          error: linkError,
          message: 'Error occurred while linking user to company',
          userData: userData
        });
        throw linkError;
      }
      
      console.log('User linked to company successfully:', data);
      
      // Call the onComplete callback with the company ID
      onComplete(companyId);
    } catch (error) {
      console.error('Error in linkUserToCompany:', error);
      setDebugInfo({
        error: error,
        message: 'Error occurred while linking user to company',
        userId: userId,
        companyId: companyId
      });
      throw error;
    }
  };

  return (
    <div className="company-registration-container">
      <div className="company-registration-card">
        <h2>Register Your Company</h2>
        <p className="info-text">
          Set up your company profile to invite team members and track your organization's progress.
        </p>

        {error && <div className="error-message">{error}</div>}
        
        {debugInfo && (
          <div className="debug-info">
            <h4>Debug Information</h4>
            <pre>{JSON.stringify(debugInfo, null, 2)}</pre>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="industry">Industry</label>
            <select
              id="industry"
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              required
            >
              <option value="">Select an industry</option>
              <option value="technology">Technology</option>
              <option value="healthcare">Healthcare</option>
              <option value="finance">Finance</option>
              <option value="education">Education</option>
              <option value="retail">Retail</option>
              <option value="manufacturing">Manufacturing</option>
              <option value="other">Other</option>
            </select>
          </div>

          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? 'Processing...' : 'Complete Registration'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CompanyRegistration; 