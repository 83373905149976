/**
 * AuthCallback - Handles authentication redirects and callback processing.
 * Processes auth parameters from URL hash fragments and redirects based on authentication status.
 */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services';

const AuthCallback = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('Processing authentication...');
  const [error, setError] = useState(null);

  useEffect(() => {
    // Get URL parameters more safely
    const processAuth = async () => {
      try {
        // Check for hash params
        if (window.location.hash) {
          const hashParams = window.location.hash.substring(1).split('&').reduce((result, item) => {
            if (item) {
              const parts = item.split('=');
              if (parts.length === 2) {
                result[parts[0]] = decodeURIComponent(parts[1]);
              }
            }
            return result;
          }, {});

          if (hashParams.error) {
            setError(`Authentication error: ${hashParams.error_description || hashParams.error}`);
          } else {
            // After email confirmation, always redirect to login
            // This ensures proper flow to company registration if needed
            setMessage('Authentication successful! Redirecting to login...');
            
            // Check for stored invite code
            const storedInviteCode = localStorage.getItem('honeyrunsInviteCode');
            if (storedInviteCode) {
              setTimeout(() => {
                navigate(`/join?code=${storedInviteCode}`, { replace: true });
              }, 1500);
            } else {
              setTimeout(() => {
                navigate('/login', { replace: true });
              }, 1500);
            }
          }
        } else {
          // No hash parameters, check for session first
          const { data } = await apiService.auth.getSession();
          
          setTimeout(() => {
            if (data.session) {
              // User is authenticated, redirect to dashboard
              navigate('/dashboard', { replace: true });
            } else {
              // No session, redirect to login
              navigate('/login', { replace: true });
            }
          }, 1500);
        }
      } catch (err) {
        console.error('Error in auth callback:', err);
        setError(err.message || 'An error occurred during authentication');
      }
    };

    processAuth();
  }, [navigate]);

  return (
    <div className="auth-callback-container">
      <div className="auth-callback-card">
        <h2>Authentication</h2>
        {error ? (
          <div className="error-message">
            <p>{error}</p>
            <p>You can close this window and try logging in again.</p>
            <button onClick={() => navigate('/login', { replace: true })}>
              Return to Login
            </button>
          </div>
        ) : (
          <div className="success-message">
            <p>{message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthCallback; 