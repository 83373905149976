import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { HiOutlineRefresh, HiPlus, HiUserGroup, HiPhone, HiPaperAirplane, HiUser, HiX, HiOutlineChat } from 'react-icons/hi';
import '../styles/CommunicationsPage.css';

function CommunicationsPage() {
  // State for conversations and messages
  const [conversations, setConversations] = useState([]);
  const [directConversations, setDirectConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  
  // State for creating a new group
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [groupParticipants, setGroupParticipants] = useState([{ phone: "" }]);
  const [groupName, setGroupName] = useState('');
  const [initialMessage, setInitialMessage] = useState('');
  
  // State for UI controls
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewChatOpen, setIsNewChatOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('direct');
  
  // Ref for message container (to auto-scroll)
  const messageContainerRef = useRef(null);

  // Load conversations on component mount
  useEffect(() => {
    fetchConversations();
    fetchDirectConversations();
    
    // Set up polling for new conversations every 30 seconds
    const intervalId = setInterval(() => {
      fetchConversations(false);
      fetchDirectConversations(false);
    }, 30000);
    
    return () => {
      clearInterval(intervalId);
    }
  }, []);

  // Auto-scroll to bottom of messages when messages change
  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Load messages when current conversation changes
  useEffect(() => {
    if (currentConversation) {
      if (currentConversation.sid.startsWith('sms_')) {
        fetchDirectMessages(currentConversation.sid);
        
        // Only fetch messages when user manually refreshes or sends a message
        // No automatic polling
      } else {
        fetchMessages(currentConversation.sid);
        
        // Only fetch messages when user manually refreshes or sends a message
        // No automatic polling
      }
    }
  }, [currentConversation]);

  // Fetch all conversations from Twilio Conversations API
  const fetchConversations = async (showLoading = true) => {
    try {
      if (showLoading) {
        setLoading(true);
      }
      setError(null);
      
      const response = await fetch('/api/conversations');
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to fetch conversations');
      }
      
      const rawResponse = await response.text();
      
      let data;
      try {
        data = JSON.parse(rawResponse);
      } catch (parseError) {
        throw new Error('Failed to parse API response');
      }
      
      if (!data || !data.conversations) {
        throw new Error('Invalid response format');
      }
      
      // Sort conversations by most recent first (using dateCreated)
      const sortedConversations = (data.conversations || []).sort((a, b) => 
        new Date(b.dateCreated) - new Date(a.dateCreated)
      );
      
      setConversations(sortedConversations);
    } catch (error) {
      setError('Failed to load conversations. Please try again.');
    } finally {
      if (showLoading) {
        setLoading(false);
      }
    }
  };

  // Fetch direct SMS conversations
  const fetchDirectConversations = async (showLoading = true) => {
    try {
      if (showLoading) {
        setLoading(true);
      }
      setError(null);
      
      const response = await fetch('/api/direct-messages');
      
      if (!response.ok) {
        throw new Error('Failed to fetch direct messages');
      }
      
      const data = await response.json();
      
      if (!data) {
        throw new Error('Empty response from API');
      }
      
      // Add participants array to each direct conversation to match the structure expected by our UI
      const processedConversations = Array.isArray(data.directConversations) 
        ? data.directConversations.map(conv => ({
            ...conv,
            participants: [], // Add empty participants array to match the expected structure
            type: 'direct', // Add type to differentiate from group conversations
            participantsCount: 1 // Assume single participant for direct messages
          }))
        : [];
        
      setDirectConversations(processedConversations);
    } catch (error) {
      console.error('Error in fetchDirectConversations:', error);
      setError('Failed to load direct messages. Please try again.');
    } finally {
      if (showLoading) {
        setLoading(false);
      }
    }
  };

  // Fetch messages for a conversation
  const fetchMessages = async (conversationSid, showLoading = true) => {
    try {
      if (showLoading) {
        setLoading(true);
      }
      setError(null);
      
      const response = await fetch(`/api/conversations/${conversationSid}/messages`);
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch messages');
      }
      
      // Sort messages by date
      const sortedMessages = (data.messages || []).sort((a, b) => 
        new Date(a.dateCreated) - new Date(b.dateCreated)
      );
      
      setMessages(sortedMessages);
    } catch (error) {
      // Don't show error for polling updates
      if (showLoading) {
        setError('Failed to load messages. Please try again.');
      }
    } finally {
      if (showLoading) {
        setLoading(false);
      }
    }
  };

  // Fetch direct messages for a contact
  const fetchDirectMessages = useCallback(async (contactSid, showLoading = true) => {
    try {
      if (showLoading) {
        setLoading(true);
      }
      setError(null);
      
      const response = await fetch(`/api/direct-messages/${contactSid}`);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch direct messages');
      }

      if (data.success && data.messages) {
        // Sort messages by date
        const sortedMessages = [...data.messages].sort((a, b) => {
          return new Date(a.dateCreated) - new Date(b.dateCreated);
        });
        setMessages(sortedMessages);
      } else {
        console.error('Error fetching messages:', data.error);
        setMessages([]);
      }
    } catch (error) {
      // Don't show error for polling updates
      if (showLoading) {
        setError('Failed to load direct messages. Please try again.');
      }
      console.error('Error fetching messages:', error);
      setMessages([]);
    } finally {
      if (showLoading) {
        setLoading(false);
      }
    }
  }, []);

  // Send a message to the current conversation
  const sendMessage = async (e) => {
    e.preventDefault();
    
    if (!newMessage.trim() || !currentConversation) {
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      // Handle sending based on conversation type
      if (currentConversation.sid.startsWith('sms_')) {
        // This is a direct SMS conversation
        const phoneNumber = `+${currentConversation.sid.replace('sms_', '')}`;
        
        const response = await fetch('/api/send-direct-message', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            to: phoneNumber,
            body: newMessage,
          }),
        });
        
        const data = await response.json();
        
        if (!response.ok) {
          throw new Error(data.error || 'Failed to send message');
        }
        
        // Log sent message details
        console.log('--- SENT MESSAGE DETAILS ---');
        console.log('Message:', data.data);
        console.log('Message Type:', data.data.sid ? data.data.sid.substring(0, 2) : 'Unknown');
        console.log('Has attributes:', 'attributes' in data.data);
        console.log('Has conversationSid:', 'conversationSid' in data.data);
        
        // Refresh the messages
        fetchDirectMessages(currentConversation.sid);
      } else {
        // This is a Twilio Conversation
        const response = await fetch('/api/send-group-message', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            conversationSid: currentConversation.sid,
            body: newMessage,
            author: 'twiliobot', // Default author
          }),
        });
        
        const data = await response.json();
        
        if (!response.ok) {
          throw new Error(data.error || 'Failed to send message');
        }
        
        // Log sent message details
        console.log('--- SENT CONVERSATION MESSAGE DETAILS ---');
        console.log('Message:', data.data);
        console.log('Message Type:', data.data.sid ? data.data.sid.substring(0, 2) : 'Unknown');
        console.log('Has attributes:', 'attributes' in data.data);
        
        // Add the new message to the list
        setMessages(prev => [...prev, data.message]);
      }
      
      // Clear input field
      setNewMessage('');
    } catch (error) {
      setError('Failed to send message. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Start a new direct conversation
  const startDirectConversation = async (e) => {
    e.preventDefault();
    
    if (!newPhoneNumber.trim()) {
      setError('Please enter a phone number');
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch('/api/send-direct-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: newPhoneNumber,
          body: initialMessage || "Hello from HoneyRuns!",
        }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to create conversation');
      }
      
      // Refresh conversations and select the new one
      await fetchConversations();
      
      if (data.conversation && data.conversation.sid) {
        setCurrentConversation(data.conversation);
      }
      
      // Clear form and close new chat panel
      setNewPhoneNumber('');
      setInitialMessage('');
      setIsNewChatOpen(false);
    } catch (error) {
      setError('Failed to create conversation. Please check the phone number and try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Create a new group conversation
  const createGroupConversation = async (e) => {
    e.preventDefault();
    
    const validParticipants = groupParticipants.filter(p => p.phone.trim() !== "");
    if (validParticipants.length < 2) {
      setError('Please enter at least 2 valid phone numbers');
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch('/api/create-group-conversation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          participants: validParticipants,
          initialMessage: initialMessage || `Group chat: ${groupName || 'New Group'}`,
        }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to create group conversation');
      }
      
      // Refresh conversations and select the new one
      await fetchConversations();
      
      if (data.conversation && data.conversation.sid) {
        setCurrentConversation({...data.conversation, friendlyName: groupName || 'New Group'});
      }
      
      // Reset group creation form and close panel
      setGroupParticipants([{ phone: "" }]);
      setGroupName('');
      setInitialMessage('');
      setIsCreatingGroup(false);
      setIsNewChatOpen(false);
    } catch (error) {
      setError('Failed to create group. Please check the phone numbers and try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Handle changes to group participants
  const handleParticipantChange = (index, value) => {
    const newParticipants = [...groupParticipants];
    newParticipants[index].phone = value;
    setGroupParticipants(newParticipants);
  };

  // Add a participant to the group
  const addParticipant = () => {
    setGroupParticipants([...groupParticipants, { phone: "" }]);
  };

  // Remove a participant from the group
  const removeParticipant = (index) => {
    if (groupParticipants.length <= 1) {
      return;
    }
    const newParticipants = [...groupParticipants];
    newParticipants.splice(index, 1);
    setGroupParticipants(newParticipants);
  };
  
  // Format date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  
  // Get the display name for a conversation
  const getConversationName = (conversation) => {
    if (!conversation) {
      return 'Unknown';
    }
    
    // For direct SMS conversations (they have a different structure)
    if (conversation.sid && conversation.sid.startsWith('sms_')) {
      // Just display the formatted phone number for direct messages
      const phoneNumber = conversation.sid.replace('sms_', '');
      return formatPhoneNumber(`+${phoneNumber}`);
    }
    
    // If it has a friendly name that's not a default format, use it
    if (conversation.friendlyName && 
        !conversation.friendlyName.startsWith('Conversation between')) {
      return conversation.friendlyName;
    }
    
    // For group chats, create a better default name
    if (conversation.participantCount > 2 || 
        (conversation.participants && conversation.participants.length > 2)) {
      return 'Group Chat';
    }
    
    // For Twilio Conversations that are actually direct messages,
    // extract and format the phone number
    if (conversation.friendlyName && conversation.friendlyName.startsWith('Conversation between')) {
      // Extract the non-Twilio number - client can't access process.env directly
      // Look for a number format like +1XXXXXXXXXX
      const numbers = conversation.friendlyName.replace('Conversation between ', '').split(' and ');
      // Check for the typical Twilio number format
      const participantNumber = numbers.find(num => !num.includes('+15128869075'));
      
      if (participantNumber) {
        return formatPhoneNumber(participantNumber);
      }
    }
    
    // Fallback to shortened SID or friendlyName
    return conversation.friendlyName || (conversation.sid ? `Chat ${conversation.sid.substring(conversation.sid.length - 6)}` : 'Unknown Chat');
  };
  
  // Format phone number for display
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    
    // Normalize the phone number format by removing any non-digit characters
    const normalizedNumber = phoneNumber.replace(/\D/g, '');
    
    // For US numbers (11 digits with country code or 10 digits without)
    if (normalizedNumber.length === 11 && normalizedNumber.startsWith('1')) {
      // Format as +1 (XXX) XXX-XXXX
      return `+1 (${normalizedNumber.substring(1, 4)}) ${normalizedNumber.substring(4, 7)}-${normalizedNumber.substring(7)}`;
    } else if (normalizedNumber.length === 10) {
      // Format as +1 (XXX) XXX-XXXX
      return `+1 (${normalizedNumber.substring(0, 3)}) ${normalizedNumber.substring(3, 6)}-${normalizedNumber.substring(6)}`;
    }
    
    // Return E.164 format for other cases
    return phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
  };
  
  // Normalize a phone number for comparison
  const normalizePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    // Remove all non-digit characters
    return phoneNumber.replace(/\D/g, '');
  };
  
  // Determine if a message was sent by the user
  const isUserMessage = (message) => {
    // If message has an explicit direction property, use that
    if (message.direction) {
      return message.direction === 'outbound-api' || message.direction === 'outbound';
    }
    
    // Fallback to the previous logic
    if (currentConversation && currentConversation.sid.startsWith('sms_')) {
      // For direct SMS messages, check the from/to
      // Client can't directly access process.env, use the known Twilio number
      const twilioNumber = '+15128869075'; // Hardcode only in client code
      return message.from === twilioNumber || message.author === 'twiliobot';
    }
    
    // For conversation messages
    return message.author === 'twiliobot';
  };

  // Combine all conversations for display with error handling
  const allConversations = useMemo(() => {
    try {
      // Check if both are arrays before combining
      const conversationsArray = Array.isArray(conversations) ? conversations : [];
      const directArray = Array.isArray(directConversations) ? directConversations : [];
      
      // Deduplicate conversations by phone number before merging
      const phoneMap = new Map();
      
      // First process Twilio Conversations
      conversationsArray.forEach(conv => {
        if (conv.friendlyName) {
          const normalizedPhone = normalizePhoneNumber(conv.friendlyName);
          if (normalizedPhone.length >= 10) {
            phoneMap.set(normalizedPhone, conv);
          } else {
            // For non-phone-based conversations, use the SID
            phoneMap.set(conv.sid, conv);
          }
        } else {
          phoneMap.set(conv.sid, conv);
        }
      });
      
      // Process direct conversations, potentially overwriting duplicates
      directArray.forEach(conv => {
        if (conv.sid && conv.sid.startsWith('sms_')) {
          const normalizedPhone = normalizePhoneNumber(conv.sid.replace('sms_', ''));
          if (normalizedPhone.length >= 10) {
            phoneMap.set(normalizedPhone, conv);
          } else {
            phoneMap.set(conv.sid, conv);
          }
        } else {
          phoneMap.set(conv.sid, conv);
        }
      });
      
      // Convert map values back to array and sort
      return Array.from(phoneMap.values()).sort((a, b) => {
        // Safely handle potential missing dateCreated
        const dateA = a && a.dateCreated ? new Date(a.dateCreated) : new Date(0);
        const dateB = b && b.dateCreated ? new Date(b.dateCreated) : new Date(0);
        return dateB - dateA;
      });
    } catch (error) {
      console.error('Error combining conversations:', error);
      return [];
    }
  }, [conversations, directConversations]);

  return (
    <div className="communications-container">
      {/* Conversations Sidebar */}
      <div className="conversations-sidebar">
        <div className="sidebar-header">
          <h2 className="sidebar-title">Messages</h2>
          <div className="sidebar-actions">
            <button 
              className="action-button" 
              onClick={() => {
                fetchConversations();
                fetchDirectConversations();
              }}
              title="Refresh"
            >
              <HiOutlineRefresh />
            </button>
            <button 
              className="action-button primary" 
              onClick={() => setIsNewChatOpen(true)}
              title="New Message"
            >
              <HiPlus />
            </button>
          </div>
        </div>
        
        <div className="conversation-list">
          {allConversations.length === 0 ? (
            <div className="empty-state">
              <div className="empty-state-icon">
                <HiOutlineChat />
              </div>
              <div className="empty-state-text">No conversations yet</div>
              <button 
                className="btn btn-primary" 
                onClick={() => setIsNewChatOpen(true)}
              >
                Start a conversation
              </button>
            </div>
          ) : (
            allConversations.map(conversation => {
              // Calculate correct participants count
              let participantsCount = 1; // Default for direct messages
              
              if (conversation.participantsCount > 0) {
                participantsCount = conversation.participantsCount;
              } else if (conversation.participants && Array.isArray(conversation.participants)) {
                participantsCount = conversation.participants.length > 0 ? 
                  conversation.participants.length : 1;
              }
              
              return (
                <div 
                  key={conversation.sid} 
                  className={`conversation-item ${currentConversation && currentConversation.sid === conversation.sid ? 'active' : ''}`}
                  onClick={() => setCurrentConversation(conversation)}
                >
                  <div className="conversation-title">
                    {conversation.type === 'direct' || 
                     (conversation.sid && conversation.sid.startsWith('sms_')) ? 
                      <HiUser /> : 
                      (participantsCount > 2 ? <HiUserGroup /> : <HiUser />)
                    }
                    {getConversationName(conversation)}
                  </div>
                  <div className="conversation-meta">
                    <span>{participantsCount} {participantsCount === 1 ? 'participant' : 'participants'}</span>
                    <span>{formatDate(conversation.dateCreated)}</span>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      
      {/* Chat Area */}
      <div className="chat-area">
        {currentConversation ? (
          <>
            <div className="chat-header">
              <div className="chat-header-icon">
                {currentConversation.type === 'direct' || 
                 (currentConversation.sid && currentConversation.sid.startsWith('sms_')) ? 
                  <HiUser /> : 
                  ((currentConversation.participantsCount > 2 || 
                   (currentConversation.participants && 
                    currentConversation.participants.length > 2)) ? 
                   <HiUserGroup /> : <HiUser />)
                }
              </div>
              <div className="chat-header-info">
                <h3 className="chat-header-title">{getConversationName(currentConversation)}</h3>
                <div className="chat-header-subtitle">
                  {(currentConversation.type === 'direct' || currentConversation.sid.startsWith('sms_')) ? 
                   '1 participant' : 
                   `${currentConversation.participantsCount || 
                      (currentConversation.participants ? 
                       currentConversation.participants.length : 0)} participants`
                  }
                </div>
              </div>
            </div>
            
            <div className="messages-container" ref={messageContainerRef}>
              {messages.length === 0 ? (
                <div className="empty-state">
                  <div className="empty-state-icon">
                    <HiOutlineChat />
                  </div>
                  <div className="empty-state-text">No messages yet</div>
                  <div>Send a message to start the conversation</div>
                </div>
              ) : (
                messages.map(message => (
                  <div 
                    key={message.sid} 
                    className={`message ${isUserMessage(message) ? 'sent' : 'received'}`}
                  >
                    <div className="message-bubble">
                      {message.body}
                    </div>
                    <div className="message-meta">
                      {message.author && message.author !== 'twiliobot' ? `${message.author} • ` : ''}
                      {formatDate(message.dateCreated)}
                    </div>
                  </div>
                ))
              )}
            </div>
            
            <form className="message-form" onSubmit={sendMessage}>
              <input
                type="text"
                className="message-input"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
                disabled={loading}
              />
              <button 
                type="submit" 
                className="send-button"
                disabled={!newMessage.trim() || loading}
              >
                <HiPaperAirplane />
              </button>
            </form>
          </>
        ) : (
          <div className="empty-state">
            <div className="empty-state-icon">
              <HiOutlineChat />
            </div>
            <div className="empty-state-text">Select a conversation or start a new one</div>
            <button 
              className="btn btn-primary" 
              onClick={() => setIsNewChatOpen(true)}
            >
              Start a conversation
            </button>
          </div>
        )}
      </div>
      
      {/* New Conversation Modal */}
      {isNewChatOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <h3 className="modal-title">New Conversation</h3>
              <button 
                className="modal-close" 
                onClick={() => {
                  setIsNewChatOpen(false);
                  setActiveTab('direct');
                  setNewPhoneNumber('');
                  setGroupName('');
                  setGroupParticipants([{ phone: "" }]);
                  setInitialMessage('');
                  setError(null);
                }}
              >
                <HiX />
              </button>
            </div>
            
            <div className="modal-body">
              <div className="tab-group">
                <div 
                  className={`tab ${activeTab === 'direct' ? 'active' : ''}`}
                  onClick={() => setActiveTab('direct')}
                >
                  Direct Message
                </div>
                <div 
                  className={`tab ${activeTab === 'group' ? 'active' : ''}`}
                  onClick={() => setActiveTab('group')}
                >
                  Group Chat
                </div>
              </div>
              
              {activeTab === 'direct' ? (
                <form onSubmit={startDirectConversation}>
                  <div className="form-group">
                    <label className="form-label">Recipient Phone Number</label>
                    <input
                      type="text"
                      className="form-input"
                      value={newPhoneNumber}
                      onChange={(e) => setNewPhoneNumber(e.target.value)}
                      placeholder="e.g., +12125551234"
                      required
                    />
                  </div>
                  
                  <div className="form-group">
                    <label className="form-label">Initial Message (Optional)</label>
                    <textarea
                      className="form-input"
                      value={initialMessage}
                      onChange={(e) => setInitialMessage(e.target.value)}
                      placeholder="Hey there! This is a message from HoneyRuns."
                      rows={4}
                    />
                  </div>
                  
                  {error && <div className="error-message">{error}</div>}
                  
                  <div className="modal-footer">
                    <button 
                      type="button" 
                      className="btn btn-secondary"
                      onClick={() => setIsNewChatOpen(false)}
                    >
                      Cancel
                    </button>
                    <button 
                      type="submit" 
                      className="btn btn-primary"
                      disabled={loading || !newPhoneNumber.trim()}
                    >
                      Start Conversation
                    </button>
                  </div>
                </form>
              ) : (
                <form onSubmit={createGroupConversation}>
                  <div className="form-group">
                    <label className="form-label">Group Name (Optional)</label>
                    <input
                      type="text"
                      className="form-input"
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                      placeholder="My Group Chat"
                    />
                  </div>
                  
                  <div className="form-group">
                    <label className="form-label">Participants</label>
                    {groupParticipants.map((participant, index) => (
                      <div key={index} className="participant-row">
                        <input
                          type="text"
                          className="form-input participant-input"
                          value={participant.phone}
                          onChange={(e) => handleParticipantChange(index, e.target.value)}
                          placeholder="Phone number (e.g., +1)"
                          required={index === 0}
                        />
                        {index > 0 && (
                          <button 
                            type="button" 
                            className="action-button"
                            onClick={() => removeParticipant(index)}
                          >
                            <HiX />
                          </button>
                        )}
                      </div>
                    ))}
                    <button 
                      type="button" 
                      className="btn btn-secondary"
                      onClick={addParticipant}
                    >
                      Add Participant
                    </button>
                  </div>
                  
                  <div className="form-group">
                    <label className="form-label">Initial Message (Optional)</label>
                    <textarea
                      className="form-input"
                      value={initialMessage}
                      onChange={(e) => setInitialMessage(e.target.value)}
                      placeholder="Welcome to our group chat!"
                      rows={4}
                    />
                  </div>
                  
                  {error && <div className="error-message">{error}</div>}
                  
                  <div className="modal-footer">
                    <button 
                      type="button" 
                      className="btn btn-secondary"
                      onClick={() => setIsNewChatOpen(false)}
                    >
                      Cancel
                    </button>
                    <button 
                      type="submit" 
                      className="btn btn-primary"
                      disabled={loading || groupParticipants.filter(p => p.phone.trim() !== "").length < 2}
                    >
                      Create Group Chat
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      )}
      
      {/* Loading indicator */}
      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
}

export default CommunicationsPage; 