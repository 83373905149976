/**
 * useInvitation - Custom hook for processing invitation codes.
 * Retrieves company information associated with an invitation and provides status feedback.
 */
import { useState, useEffect } from 'react';
import { apiService } from '../../services';

export const useInvitation = (inviteCode) => {
  const [companyName, setCompanyName] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  
  useEffect(() => {
    if (inviteCode) {
      fetchCompanyInfo(inviteCode);
    }
  }, [inviteCode]);
  
  const fetchCompanyInfo = async (code) => {
    if (!code) return;
    
    setIsLoading(true);
    setIsError(false);
    
    try {
      // Get invitation details
      const { data: invite, error } = await apiService.invitations.getInvitation(code);
      
      if (error || !invite) {
        setMessage('Invalid invitation code');
        setIsError(true);
        return;
      }

      // Get company details
      const { data: company, error: companyError } = await apiService.company.getCompany(invite.company_id);

      // Set company name from the invitation
      if (company && !companyError) {
        setCompanyName(company.name);
        setMessage(`You've been invited to join ${company.name}`);
      } else {
        setMessage('You\'ve been invited to join a company on HoneyRuns');
      }
    } catch (err) {
      console.error('Error fetching company info:', err);
      setIsError(true);
      setMessage('Failed to retrieve invitation details');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    companyName,
    message,
    isLoading,
    isError
  };
}; 