/**
 * MainLayout - Primary layout component for authenticated application pages.
 * Includes a navigation sidebar and handles session-based access control.
 * Contains a nested Sidebar component for navigation.
 */
import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { supabase } from '../../services';

// Simple sidebar navigation
const Sidebar = ({ userRole }) => {
  const isAdmin = userRole === 'admin';
  
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>HoneyRuns</h2>
      </div>
      <nav className="sidebar-nav">
        <ul>
          <li><Link to="/dashboard" className="nav-link">Dashboard</Link></li>
          <li><Link to="/telematics" className="nav-link">Telematics</Link></li>
          <li><Link to="/communications" className="nav-link">Communications</Link></li>
          {isAdmin && <li><Link to="/admin" className="nav-link">Admin Settings</Link></li>}
        </ul>
      </nav>
      <div className="sidebar-footer">
        <button onClick={() => supabase.auth.signOut()}>Sign Out</button>
      </div>
    </div>
  );
};

// Main layout component for authenticated pages
const MainLayout = ({ children, session, userProfile }) => {
  // Redirect to login if no session
  if (!session) {
    return <Navigate to="/" replace />;
  }

  // Get user role (admin or regular user)
  const userRole = userProfile?.isAdmin ? 'admin' : 'user';

  return (
    <div className="main-layout">
      <Sidebar userRole={userRole} />
      <div className="main-content">
        <header className="main-header">
          <div className="user-info">
            {session?.user?.email || 'User'}
            {userProfile?.companyName && ` • ${userProfile.companyName}`}
          </div>
        </header>
        <main>
          {children}
        </main>
      </div>
    </div>
  );
};

export default MainLayout; 