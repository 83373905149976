import React, { useState, useEffect } from 'react';
import InviteMembers from '../components/company/InviteMembers';
import CompanySettings from '../components/company/CompanySettings';
import PersonnelUpload from '../components/company/PersonnelUpload';
import TelematicsConnect from '../components/telematics/TelematicsConnect';
import '../styles/AdminDashboard.css';

const AdminDashboard = ({ userProfile }) => {
  const [activeTab, setActiveTab] = useState('company');
  const [companyData, setCompanyData] = useState(null);
  
  useEffect(() => {
    // Set initial company data from the userProfile
    if (userProfile && userProfile.companyId) {
      setCompanyData({
        id: userProfile.companyId,
        name: userProfile.companyName || '',
        industry: userProfile.industry || ''
      });
    }
  }, [userProfile]);
  
  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      
      <div className="admin-tabs">
        <button 
          className={`tab-button ${activeTab === 'company' ? 'active' : ''}`}
          onClick={() => setActiveTab('company')}
        >
          Company Settings
        </button>
        <button 
          className={`tab-button ${activeTab === 'users' ? 'active' : ''}`}
          onClick={() => setActiveTab('users')}
        >
          Manage Users
        </button>
        <button 
          className={`tab-button ${activeTab === 'personnel' ? 'active' : ''}`}
          onClick={() => setActiveTab('personnel')}
        >
          Operations Personnel
        </button>
        <button 
          className={`tab-button ${activeTab === 'connect' ? 'active' : ''}`}
          onClick={() => setActiveTab('connect')}
        >
          Connect
        </button>
      </div>
      
      <div className="admin-content">
        {activeTab === 'company' && (
          <CompanySettings 
            companyId={companyData?.id} 
            initialCompanyData={companyData} 
          />
        )}
        
        {activeTab === 'users' && (
          <div className="user-management">
            <h2>Manage Users</h2>
            <InviteMembers 
              companyName={userProfile?.companyName} 
              companyId={userProfile?.companyId}
              userId={userProfile?.userId}
              userProfile={userProfile}
            />
          </div>
        )}
        
        {activeTab === 'personnel' && (
          <PersonnelUpload companyId={companyData?.id} />
        )}

        {activeTab === 'connect' && (
          <TelematicsConnect userProfile={userProfile} />
        )}
      </div>
    </div>
  );
};

export default AdminDashboard; 