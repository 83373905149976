import { createClient } from '@supabase/supabase-js';

// Use environment variables with REACT_APP_ prefix for client-side code
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

// Get the current site URL for redirects
const siteUrl = window.location.origin;

// Create Supabase client with redirect config
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    redirectTo: `${siteUrl}/auth/callback`
  }
});

// Note: We removed the custom auth redirects which were causing URL construction errors
// Email confirmations will use the default redirect URLs set in Supabase dashboard 