import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = ({ userProfile }) => {
  // Get current time to display greeting
  const currentHour = new Date().getHours();
  let greeting = "Good morning";
  
  if (currentHour >= 12 && currentHour < 17) {
    greeting = "Good afternoon";
  } else if (currentHour >= 17) {
    greeting = "Good evening";
  }

  return (
    <div className="dashboard-page">
      <div className="dashboard-welcome">
        <h1>{greeting}, {userProfile?.name || 'there'}!</h1>
        <p>Welcome to HoneyRuns. Your fleet management simplified.</p>
      </div>
      
      <div className="dashboard-overview">
        <div className="overview-card">
          <h3>Vehicles</h3>
          <div className="card-content">
            <div className="stat-number">12</div>
            <div className="stat-label">Total Vehicles</div>
          </div>
          <div className="card-footer">
            <div className="stat-item">
              <span className="active">10 Active</span>
            </div>
            <div className="stat-item">
              <span className="inactive">2 Inactive</span>
            </div>
          </div>
        </div>
        
        <div className="overview-card">
          <h3>Team</h3>
          <div className="card-content">
            <div className="stat-number">5</div>
            <div className="stat-label">Team Members</div>
          </div>
          <div className="card-footer">
            {userProfile?.isAdmin && (
              <Link to="/admin" className="card-link">Manage Team</Link>
            )}
          </div>
        </div>
      </div>
      
      <div className="dashboard-shortcuts">
        <h2>Quick Actions</h2>
        <div className="shortcuts-grid">
          <Link to="/telematics" className="shortcut-card">
            <h3>View Fleet</h3>
            <p>Check vehicle locations and status</p>
          </Link>
          {userProfile?.isAdmin && (
            <Link to="/admin" className="shortcut-card admin-card">
              <h3>Admin Panel</h3>
              <p>Manage company settings and users</p>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard; 