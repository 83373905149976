/**
 * PersonnelUpload - Manages operations personnel data with Excel import support.
 * Provides features for adding, editing, and removing personnel records,
 * including bulk upload via spreadsheets.
 */
import React, { useState, useEffect } from 'react';
import { supabase } from '../../services';
import '../../styles/PersonnelUpload.css';
import * as XLSX from 'xlsx';

const PersonnelUpload = ({ companyId }) => {
  const [personnel, setPersonnel] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [editMode, setEditMode] = useState({});
  const [editValues, setEditValues] = useState({});
  const [isAdding, setIsAdding] = useState(false);
  const [newPerson, setNewPerson] = useState({
    name: '',
    phone: '',
    email: '',
    position: ''
  });

  // Helper function to check if an ID is a UUID
  const isUuid = (id) => {
    return typeof id === 'string' && (id.includes('-') || id.length === 36);
  };

  // Load existing personnel on component mount
  useEffect(() => {
    if (companyId) {
      loadPersonnel();
    }
  }, [companyId]);

  const loadPersonnel = async () => {
    try {
      setLoading(true);
      
      // Check if companyId is a UUID (from Supabase)
      if (isUuid(companyId)) {
        // Try to fetch from Supabase
        try {
          const { data, error } = await supabase
            .from('operations_personnel')
            .select('*')
            .eq('company_id', companyId);
          
          if (error) throw error;
          
          if (data && data.length > 0) {
            setPersonnel(data);
            return;
          }
        } catch (supabaseError) {
          console.error('Supabase error:', supabaseError);
          // If Supabase fails, fall back to API
        }
      }
      
      // Fall back to API if Supabase doesn't have data or companyId is not UUID
      const response = await fetch(`/api/admin/personnel?companyId=${companyId}`);
      const result = await response.json();
      
      if (result.success) {
        setPersonnel(result.data || []);
      } else {
        throw new Error(result.message || 'Failed to load personnel data');
      }
    } catch (error) {
      console.error('Error loading personnel:', error);
      setUploadStatus({
        success: false,
        message: 'Failed to load personnel data. Please try refreshing the page.'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    setLoading(true);
    setUploadStatus(null);
    setIsError(false);
    
    try {
      // Read the Excel file
      const reader = new FileReader();
      
      reader.onload = async (e) => {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          
          // Get the first worksheet
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          
          // Convert to JSON
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          
          if (jsonData.length === 0) {
            setUploadStatus({
              success: false,
              message: 'The Excel file contains no data. Please check the file and try again.'
            });
            setLoading(false);
            return;
          }
          
          // Validate and transform the data
          const personnel = jsonData.map(row => {
            // Try to extract the required fields from the row
            // This assumes the Excel has columns Name, Phone, Email, Position
            // Adjust field mapping as needed based on your template
            return {
              name: row.Name || row.name || '',
              phone: row.Phone || row.phone || '',
              email: row.Email || row.email || '',
              position: row.Position || row.position || ''
            };
          }).filter(person => person.name && person.email);
          
          if (personnel.length === 0) {
            setUploadStatus({
              success: false,
              message: 'No valid personnel records found. Please check your Excel format.'
            });
            setLoading(false);
            return;
          }
          
          // Batch insert to Supabase if possible
          let inserted = false;
          try {
            const { data, error } = await supabase
              .from('operations_personnel')
              .insert(personnel.map(person => ({
                company_id: companyId,
                ...person
              })))
              .select();
            
            if (error) throw error;
            
            if (data && data.length > 0) {
              // Add the new personnel to the existing list
              setPersonnel(prev => [...prev, ...data]);
              inserted = true;
            }
          } catch (supabaseError) {
            console.error('Supabase error:', supabaseError);
            // Fall back to API if Supabase fails
          }
          
          // If not inserted via Supabase, try the API
          if (!inserted) {
            // Call the server API to upload the parsed personnel data
            const response = await fetch('/api/admin/personnel/upload', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                companyId,
                personnel
              }),
            });
            
            const result = await response.json();
            
            if (!result.success) {
              throw new Error(result.message || 'Failed to upload personnel');
            }
            
            // Add mock IDs to the personnel if the API doesn't provide them
            const newPersonnel = personnel.map((person, index) => ({
              id: `temp-${Date.now()}-${index}`,
              ...person
            }));
            
            // Add the new personnel to the existing list
            setPersonnel(prev => [...prev, ...newPersonnel]);
          }
          
          // Show success message
          setUploadStatus({
            success: true,
            message: `Successfully processed ${file.name}. Added ${personnel.length} personnel records.`
          });
        } catch (error) {
          console.error('Error processing Excel file:', error);
          setUploadStatus({
            success: false,
            message: 'Failed to process Excel file. Please check the format and try again.'
          });
        } finally {
          setLoading(false);
        }
      };
      
      reader.onerror = () => {
        setUploadStatus({
          success: false,
          message: 'Error reading the file. Please try again.'
        });
        setLoading(false);
      };
      
      // Read the file as an array buffer
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('Error handling file upload:', error);
      setUploadStatus({
        success: false,
        message: 'Failed to process the file. Please try again.'
      });
      setLoading(false);
      // Clear the file input
      e.target.value = null;
    }
  };

  // Handler for toggling edit mode for a row
  const toggleEditMode = (id) => {
    const person = personnel.find(p => p.id === id);
    setEditMode(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
    setEditValues(prev => ({
      ...prev,
      [id]: { ...person }
    }));
  };

  // Handler for changing values in edit mode
  const handleEditChange = (id, field, value) => {
    setEditValues(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value
      }
    }));
  };

  // Handler for saving edited row
  const saveEdit = async (id) => {
    try {
      const updatedPerson = editValues[id];
      
      if (!updatedPerson.name || !updatedPerson.email) {
        setUploadStatus({
          success: false,
          message: 'Name and email are required'
        });
        return;
      }
      
      setLoading(true);
      
      // Check if the ID is a UUID
      if (isUuid(id)) {
        // If it's a UUID, try to update in Supabase
        try {
          const { data, error } = await supabase
            .from('operations_personnel')
            .update({
              name: updatedPerson.name,
              email: updatedPerson.email,
              phone: updatedPerson.phone,
              position: updatedPerson.position
            })
            .eq('id', id)
            .select();
          
          if (error) throw error;
          
          if (data && data.length > 0) {
            // Update the local state with the Supabase result
            setPersonnel(prev => 
              prev.map(person => 
                person.id === id ? data[0] : person
              )
            );
            
            setEditMode(prev => ({
              ...prev,
              [id]: false
            }));
            
            // Show success message
            setUploadStatus({
              success: true,
              message: 'Personnel information updated successfully'
            });
            setTimeout(() => setUploadStatus(null), 3000);
            return;
          }
        } catch (supabaseError) {
          console.error('Supabase error:', supabaseError);
          // If Supabase fails, fall back to API
        }
      }
      
      // Fall back to API or for non-UUID IDs
      const response = await fetch(`/api/admin/personnel/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          companyId,
          personnel: updatedPerson
        }),
      });
      
      const result = await response.json();
      
      if (!result.success) {
        throw new Error(result.message || 'Failed to update personnel');
      }
      
      // Update the local state with the result
      setPersonnel(prev => 
        prev.map(person => 
          person.id === id ? result.data : person
        )
      );
      
      setEditMode(prev => ({
        ...prev,
        [id]: false
      }));
      
      // Show success message
      setUploadStatus({
        success: true,
        message: 'Personnel information updated successfully'
      });
      setTimeout(() => setUploadStatus(null), 3000);
    } catch (error) {
      console.error('Error updating personnel:', error);
      setUploadStatus({
        success: false,
        message: 'Failed to update personnel information'
      });
    } finally {
      setLoading(false);
    }
  };

  // Handler for cancel edit
  const cancelEdit = (id) => {
    setEditMode(prev => ({
      ...prev,
      [id]: false
    }));
  };

  // Handler for adding a new person
  const handleAddChange = (field, value) => {
    setNewPerson(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Handler for saving new person
  const saveNewPerson = async () => {
    if (!newPerson.name || !newPerson.email) {
      setUploadStatus({
        success: false,
        message: 'Name and email are required'
      });
      return;
    }
    
    try {
      setLoading(true);
      
      // Try to insert into Supabase first
      try {
        const { data, error } = await supabase
          .from('operations_personnel')
          .insert({
            company_id: companyId,
            name: newPerson.name,
            email: newPerson.email,
            phone: newPerson.phone,
            position: newPerson.position
          })
          .select();
        
        if (error) throw error;
        
        if (data && data.length > 0) {
          // Add the new personnel to the list from Supabase result
          setPersonnel(prev => [...prev, data[0]]);
          
          // Reset the form
          setNewPerson({
            name: '',
            phone: '',
            email: '',
            position: ''
          });
          setIsAdding(false);
          
          // Show success message
          setUploadStatus({
            success: true,
            message: 'New personnel added successfully'
          });
          setTimeout(() => setUploadStatus(null), 3000);
          return;
        }
      } catch (supabaseError) {
        console.error('Supabase error:', supabaseError);
        // If Supabase fails, fall back to API
      }
      
      // Fall back to API
      const response = await fetch('/api/admin/personnel', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          companyId,
          personnel: newPerson
        }),
      });
      
      const result = await response.json();
      
      if (!result.success) {
        throw new Error(result.message || 'Failed to add personnel');
      }
      
      // Add the new personnel to the list
      setPersonnel(prev => [...prev, result.data]);
      
      // Reset the form
      setNewPerson({
        name: '',
        phone: '',
        email: '',
        position: ''
      });
      setIsAdding(false);
      
      // Show success message
      setUploadStatus({
        success: true,
        message: 'New personnel added successfully'
      });
      setTimeout(() => setUploadStatus(null), 3000);
    } catch (error) {
      console.error('Error adding personnel:', error);
      setUploadStatus({
        success: false,
        message: 'Failed to add new personnel'
      });
    } finally {
      setLoading(false);
    }
  };

  // Add this function after the saveNewPerson function
  const downloadTemplate = () => {
    try {
      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      
      // Create template data with headers and one sample row
      const templateData = [
        { Name: 'John Smith', Phone: '+1 555-123-4567', Email: 'john@example.com', Position: 'Driver' }
      ];
      
      // Create a worksheet from the data
      const worksheet = XLSX.utils.json_to_sheet(templateData);
      
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Personnel Template');
      
      // Generate the Excel file
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      
      // Create a Blob from the buffer
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
      // Create a temporary URL to the blob
      const url = URL.createObjectURL(blob);
      
      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'personnel_template.xlsx';
      
      // Append the link to the document
      document.body.appendChild(link);
      
      // Click the link to trigger the download
      link.click();
      
      // Remove the link
      document.body.removeChild(link);
      
      // Show success message
      setUploadStatus({
        success: true,
        message: 'Template downloaded successfully'
      });
      setTimeout(() => setUploadStatus(null), 3000);
    } catch (error) {
      console.error('Error generating template:', error);
      setUploadStatus({
        success: false,
        message: 'Failed to download template'
      });
    }
  };

  return (
    <div className="personnel-upload">
      <h2>Operations Personnel</h2>
      <p className="upload-description">
        Upload an Excel file with your operations personnel information or add records manually.
        This will help you manage communications with your team.
      </p>
      
      <div className="file-upload-container">
        <h3>Add Personnel</h3>
        
        <div className="upload-options">
          <div className="upload-option">
            <h4>Upload Excel File</h4>
            <p className="upload-note">
              File should contain: Name, Phone Number, Email, and Position
            </p>
            
            <div className="file-upload">
              <label className="file-label">
                <span className="file-label-text">Choose Excel File</span>
                <input 
                  type="file" 
                  accept=".xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                  onChange={handleFileUpload}
                  disabled={loading}
                />
              </label>
              
              {loading && (
                <div className="loading-indicator">
                  Processing file...
                </div>
              )}
            </div>
            
            <button className="template-button" onClick={downloadTemplate}>
              Download Template
            </button>
          </div>
          
          <div className="upload-option">
            <h4>Or Add Manually</h4>
            <button 
              className="add-button" 
              onClick={() => setIsAdding(true)}
              disabled={isAdding}
            >
              Add New Person
            </button>
          </div>
        </div>
        
        {uploadStatus && (
          <div className={`upload-status ${uploadStatus.success ? 'success' : 'error'}`}>
            {uploadStatus.message}
          </div>
        )}
      </div>
      
      <div className="personnel-list">
        <h3>Current Personnel</h3>
        {personnel.length > 0 || isAdding ? (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Position</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {personnel.map(person => (
                <tr key={person.id}>
                  {editMode[person.id] ? (
                    // Editable row
                    <>
                      <td>
                        <input 
                          type="text" 
                          value={editValues[person.id]?.name || ''} 
                          onChange={(e) => handleEditChange(person.id, 'name', e.target.value)}
                        />
                      </td>
                      <td>
                        <input 
                          type="text" 
                          value={editValues[person.id]?.phone || ''} 
                          onChange={(e) => handleEditChange(person.id, 'phone', e.target.value)}
                        />
                      </td>
                      <td>
                        <input 
                          type="email" 
                          value={editValues[person.id]?.email || ''} 
                          onChange={(e) => handleEditChange(person.id, 'email', e.target.value)}
                        />
                      </td>
                      <td>
                        <select 
                          value={editValues[person.id]?.position || ''} 
                          onChange={(e) => handleEditChange(person.id, 'position', e.target.value)}
                        >
                          <option value="Driver">Driver</option>
                          <option value="Dispatcher">Dispatcher</option>
                          <option value="Foreman">Foreman</option>
                          <option value="Manager">Manager</option>
                          <option value="Other">Other</option>
                        </select>
                      </td>
                      <td className="action-buttons">
                        <button className="save-button" onClick={() => saveEdit(person.id)}>
                          Save
                        </button>
                        <button className="cancel-button" onClick={() => cancelEdit(person.id)}>
                          Cancel
                        </button>
                      </td>
                    </>
                  ) : (
                    // Display row
                    <>
                      <td>{person.name}</td>
                      <td>{person.phone}</td>
                      <td>{person.email}</td>
                      <td>{person.position}</td>
                      <td className="action-buttons">
                        <button className="edit-button" onClick={() => toggleEditMode(person.id)}>
                          Edit
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
              {isAdding && (
                <tr className="new-row">
                  <td>
                    <input 
                      type="text" 
                      placeholder="Name"
                      value={newPerson.name} 
                      onChange={(e) => handleAddChange('name', e.target.value)}
                    />
                  </td>
                  <td>
                    <input 
                      type="text" 
                      placeholder="Phone"
                      value={newPerson.phone} 
                      onChange={(e) => handleAddChange('phone', e.target.value)}
                    />
                  </td>
                  <td>
                    <input 
                      type="email" 
                      placeholder="Email"
                      value={newPerson.email} 
                      onChange={(e) => handleAddChange('email', e.target.value)}
                    />
                  </td>
                  <td>
                    <select 
                      value={newPerson.position} 
                      onChange={(e) => handleAddChange('position', e.target.value)}
                    >
                      <option value="">Select Position</option>
                      <option value="Driver">Driver</option>
                      <option value="Dispatcher">Dispatcher</option>
                      <option value="Foreman">Foreman</option>
                      <option value="Manager">Manager</option>
                      <option value="Other">Other</option>
                    </select>
                  </td>
                  <td className="action-buttons">
                    <button className="save-button" onClick={saveNewPerson}>
                      Add
                    </button>
                    <button className="cancel-button" onClick={() => setIsAdding(false)}>
                      Cancel
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <p className="no-personnel">No personnel records found. Upload an Excel file or add personnel manually.</p>
        )}
      </div>
    </div>
  );
};

export default PersonnelUpload; 